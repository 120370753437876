export default {
  dark: {
    accent: '#5eb1b0',
    background: '#272930',
    black: '#fff',
    blue: {
      'accent-1': '#64b5ff',
      'accent-2': '#449aff',
      'accent-3': '#297fff',
      'accent-4': '#2962ff',
      base: '#6b9eff',
      'darken-1': '#114ca7',
      'darken-2': '#0265be',
      'darken-3': '#277ddc',
      'darken-4': '#4e99ff',
      'lighten-1': '#6890df',
      'lighten-2': '#b5cfff',
      'lighten-3': '#506aa0',
      'lighten-4': '#40507b',
      'lighten-5': '#3b4259',
    },
    brown: {
      base: '#795548',
      'darken-1': '#6D4C41',
      'darken-2': '#5D4037',
      'darken-3': '#4E342E',
      'darken-4': '#3E2723',
      'lighten-1': '#8D6E63',
      'lighten-2': '#A1887F',
      'lighten-3': '#BCAAA4',
      'lighten-4': '#D7CCC8',
      'lighten-5': '#EFEBE9',
    },
    'deep-blue': {
      base: '#0966ee',
      'darken-1': '#053d8f',
      'darken-2': '#0647a7',
      'darken-3': '#0752be',
      'darken-4': '#085cd6',
      'lighten-1': '#0f5ac8',
      'lighten-2': '#1450ac',
      'lighten-3': '#18488f',
      'lighten-4': '#1e3c69',
      'lighten-5': '#22324d',
    },
    'deep-purple': {
      base: '#7E4FB9',
      'darken-1': '#3D1D99',
      'darken-2': '#5429AE',
      'darken-3': '#6A38BC',
      'darken-4': '#834DC8',
      'lighten-1': '#7149A4',
      'lighten-2': '#644490',
      'lighten-3': '#523C74',
      'lighten-4': '#413459',
      'lighten-5': '#342F45',
    },
    error: '#FF5252',
    'grey-pink': {
      'base': '#D05784',
      'darken-1': '#772962',
      'darken-2': '#953B6F',
      'darken-3': '#B24A80',
      'darken-4': '#C85789',
      'lighten-1': '#B75077',
      'lighten-2': '#9D496B',
      'lighten-3': '#7B405A',
      'lighten-4': '#5A3749',
      'lighten-5': '#40303D',
    },
    indigo: {
      'accent-1': '#8C9EFF',
      'accent-2': '#536DFE',
      'accent-3': '#3D5AFE',
      'accent-4': '#304FFE',
      'base': '#3F51B5',
      'darken-1': '#1A237E',
      'darken-2': '#283593',
      'darken-3': '#303F9F',
      'darken-4': '#3949AB',
      'lighten-1': '#38458D',
      'lighten-2': '#343F79',
      'lighten-3': '#313965',
      'lighten-4': '#2D3351',
      'lighten-5': '#292D3D',
    },
    green: {
      'base': '#60AF4C',
      'darken-1': '#4AA043',
      'darken-2': '#388E3C',
      'darken-3': '#2E7D32',
      'darken-4': '#1B5E20',
      'lighten-1': '#78BB67',
      'lighten-2': '#90C782',
      'lighten-3': '#AFD7A5',
      'lighten-4': '#CFE7C9',
      'lighten-5': '#E7F3E4',
    },
    grey: {
      'base': '#61697a',
      'darken-1': '#757575',
      'lighten-2': '#e0e0e0',
      'lighten-3': '#eee',
      'lighten-4': '#f5f5f5',
    },
    orange: {
      'accent-1': '#ffdca8',
      'accent-2': '#ffd180',
      'accent-3': '#ffab40',
      'accent-4': '#ff9100',
      base: '#ffa552',
      'darken-1': '#d65e1b',
      'darken-2': '#f56700',
      'darken-3': '#ff800b',
      'darken-4': '#ff922e',
      'desaturate-1': '#605858',
      'desaturate-2': '#ab9981',
      'lighten-1': '#e19450',
      'lighten-2': '#c2834d',
      'lighten-3': '#9a6b4a',
      'lighten-4': '#725747',
      'lighten-5': '#4d4244',
    },
    pink: '#f25e94',
    primary: '#FFA552',
    purple: {
      'accent-1': '#a27bff',
      'accent-2': '#9062ff',
      'accent-3': '#7e48ff',
      'accent-4': '#6c2fff',
      base: '#b495ff',
      'darken-1': '#5e35b1',
      'darken-2': '#6f41cc',
      'darken-3': '#8549e7',
      'darken-4': '#bc87fe',
      'lighten-1': '#a890e3',
      'lighten-2': '#8e78c6',
      'lighten-3': '#7465a0',
      'lighten-4': '#554e6f',
      'lighten-5': '#444159',
    },
    red: {
      'accent-1': '#ff8a80',
      'accent-2': '#ff5252',
      'accent-3': '#ff1744',
      'accent-4': '#d50000',
      base: '#f44336',
      'darken-1': '#b71c1c',
      'darken-2': '#c62828',
      'darken-3': '#e03434',
      'darken-4': '#f44646',
      'lighten-1': '#d14b43',
      'lighten-2': '#bb3f3a',
      'lighten-3': '#943c41',
      'lighten-4': '#6f4350',
      'lighten-5': '#4c3b47',
    },
    secondary: '#6B9EFF',
    shadow: '#15161d',
    teal: {
      'accent-1': '#bbfff0',
      'accent-2': '#64ffda',
      'accent-3': '#1de9be',
      'accent-4': '#00baa0',
      base: '#56c0ad',
      'darken-1': '#436f88',
      'darken-2': '#498793',
      'darken-3': '#4c989b',
      'darken-4': '#5eb1b0',
      'lighten-1': '#77acae',
      'lighten-2': '#5f878d',
      'lighten-3': '#54737a',
      'lighten-4': '#4a5e67',
      'lighten-5': '#3c434e',
    },
    white: '#353842',
  },
  light: {
    accent: '#436F88',
    background: '#f6f9f8',
    black: '#353535',
    blue: {
      'accent-1': '#64b5ff',
      'accent-2': '#449aff',
      'accent-3': '#297fff',
      'accent-4': '#2962ff',
      base: '#6b9eff',
      'darken-1': '#468ae6',
      'darken-2': '#2b7bd4',
      'darken-3': '#0265be',
      'darken-4': '#114ca7',
      'lighten-1': '#81adff',
      'lighten-2': '#97bbff',
      'lighten-3': '#b5cfff',
      'lighten-4': '#d3e2ff',
      'lighten-5': '#edf5ff',
    },
    brown: {
      base: '#795548',
      'darken-1': '#6D4C41',
      'darken-2': '#5D4037',
      'darken-3': '#4E342E',
      'darken-4': '#3E2723',
      'lighten-1': '#8D6E63',
      'lighten-2': '#A1887F',
      'lighten-3': '#BCAAA4',
      'lighten-4': '#D7CCC8',
      'lighten-5': '#EFEBE9',
    },
    'deep-blue': {
      base: '#0966ee',
      'darken-1': '#085cd6',
      'darken-2': '#0752be',
      'darken-3': '#0647a7',
      'darken-4': '#053d8f',
      'lighten-1': '#5394f4',
      'lighten-2': '#78abf6',
      'lighten-3': '#9dc2f8',
      'lighten-4': '#bed7fb',
      'lighten-5': '#dae8fd',
    },
    'deep-purple': {
      base: '#8141D0',
      'darken-1': '#834DC8',
      'darken-2': '#6A38BC',
      'darken-3': '#5429AE',
      'darken-4': '#3D1D99',
      'lighten-1': '#AB7BDA',
      'lighten-2': '#B997E5',
      'lighten-3': '#CCB3EC',
      'lighten-4': '#DFD0F3',
      'lighten-5': '#F1EAFA',
    },
    error: '#FF5252',
    indigo: {
      'accent-1': '#8C9EFF',
      'accent-2': '#536DFE',
      'accent-3': '#3D5AFE',
      'accent-4': '#304FFE',
      'base': '#3F51B5',
      'darken-1': '#3949AB',
      'darken-2': '#303F9F',
      'darken-3': '#283593',
      'darken-4': '#1A237E',
      'lighten-1': '#5C6BC0',
      'lighten-2': '#7986CB',
      'lighten-3': '#9FA8DA',
      'lighten-4': '#C5CAE9',
      'lighten-5': '#E8EAF6',
    },
    'grey-pink': {
      'base': '#E14B84',
      'darken-1': '#C85789',
      'darken-2': '#B24A80',
      'darken-3': '#953B6F',
      'darken-4': '#772962',
      'lighten-1': '#E97CA5',
      'lighten-2': '#EE97B8',
      'lighten-3': '#F2B2CA',
      'lighten-4': '#F7D2E0',
      'lighten-5': '#FBE8EF',
    },
    green: {
      'base': '#60AF4C',
      'darken-1': '#4AA043',
      'darken-2': '#388E3C',
      'darken-3': '#2E7D32',
      'darken-4': '#1B5E20',
      'lighten-1': '#78BB67',
      'lighten-2': '#90C782',
      'lighten-3': '#AFD7A5',
      'lighten-4': '#CFE7C9',
      'lighten-5': '#E7F3E4',
    },
    grey: {
      'base': '#c0d1db',
      'darken-1': '#757575',
      'lighten-2': '#e0e0e0',
      'lighten-3': '#eee',
      'lighten-4': '#f5f5f5',
    },
    orange: {
      'accent-1': '#ffdca8',
      'accent-2': '#ffd180',
      'accent-3': '#ffab40',
      'accent-4': '#ff9100',
      base: '#ffa552',
      'darken-1': '#ff922e',
      'darken-2': '#ff800b',
      'darken-3': '#f56700',
      'darken-4': '#d65e1b',
      'desaturate-1': '#f2efe3',
      'desaturate-2': '#edd5b2',
      'lighten-1': '#ffb36c',
      'lighten-2': '#ffc086',
      'lighten-3': '#ffd2a8',
      'lighten-4': '#ffe4cb',
      'lighten-5': '#fff4ea',
    },
    pink: '#f25e94',
    primary: '#FFA552',
    purple: {
      'accent-1': '#a27bff',
      'accent-2': '#9062ff',
      'accent-3': '#7e48ff',
      'accent-4': '#6c2fff',
      base: '#b495ff',
      'darken-1': '#A66FFF',
      'darken-2': '#915DE8',
      'darken-3': '#794CD5',
      'darken-4': '#673FB8',
      'lighten-1': '#bfa5ff',
      'lighten-2': '#cbb5ff',
      'lighten-3': '#d9caff',
      'lighten-4': '#e9dfff',
      'lighten-5': '#f6f2ff',
    },
    red: {
      base: '#f44336',
      'darken-1': '#e53935',
      'darken-2': '#d32f2f',
      'darken-3': '#c62828',
      'darken-4': '#b71c1c',
      'lighten-1': '#ef5350',
      'lighten-2': '#e57373',
      'lighten-3': '#ef9a9a',
      'lighten-4': '#ffcdd2',
      'lighten-5': '#ffebee',
    },
    secondary: '#6B9EFF',
    shadow: '#c6dff1',
    teal: {
      'accent-1': '#bbfff0',
      'accent-2': '#64ffda',
      'accent-3': '#1de9be',
      'accent-4': '#00baa0',
      base: '#56c0ad',
      'darken-1': '#60aaa9',
      'darken-2': '#4c989b',
      'darken-3': '#498793',
      'darken-4': '#436f88',
      'lighten-1': '#97c7cb',
      'lighten-2': '#add5d8',
      'lighten-3': '#bfe2e4',
      'lighten-4': '#daeff0',
      'lighten-5': '#f6f9f8',
    },
    white: '#fff',
  },
}
