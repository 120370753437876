import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  FaqGetParams,
  FaqGetRequest, FaqIdDeleteRequest, FaqIdGetRequest, FaqIdPostRequest, FaqPostRequest, FaqResource, FaqStoreRequest,
  ISupportFilter, MessageResource,
  MessageStore, NameValueResource,
  SupportGetRequest, SupportIdClosePostRequest, SupportIdGetRequest, SupportIdMessagePostRequest, SupportPostRequest,
  TicketLargeResource,
  TicketShortResourcePaginator,
  TicketStore,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Работа с техподдержкой
 * - создание тикетов
 * - получение списка тикетов
 * - просмотр, ответ и закрытие тикета
 */

@Module({
  dynamic: true,
  name: 'Support',
  namespaced: true,
  store,
})
class Support extends VuexModule {
  // ---------------------------- Tickets ---------------------------- >>
  // Filter
  ticketsFilter: ISupportFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setTicketsFilter (payload: ISupportFilter) {
    this.ticketsFilter = Object.assign({}, payload)
  }

  // Entities
  tickets: TicketShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setTickets (payload: TicketShortResourcePaginator) {
    this.tickets = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchTickets(loading = true) {
    const { data } = await SupportGetRequest(tableOptionsToParams(this.ticketsFilter), { loading })

    this.setTickets(data)

    return data
  }

  // ---------------------------- Ticket ---------------------------- >>

  ticket: TicketLargeResource | null = null

  @Mutation
  setTicket (payload: TicketLargeResource) {
    this.ticket = Object.assign({}, payload)
  }

  @Mutation
  addTicketMessage (payload: MessageResource) {
    if (this.ticket && this.ticket.id === payload.parentId) {
      this.ticket.messages.push(payload)
    }
  }

  @Action({ rawError: true })
  async fetchTicket (ticketID: number) {
    const { data } = await SupportIdGetRequest(ticketID)

    this.setTicket(data)

    return data
  }

  @Action({ rawError: true })
  async createTicket (payload: TicketStore) {
    const { data } = await SupportPostRequest(payload)

    return data
  }

  @Action({ rawError: true })
  async sendTicketMessage (payload: { ticketID: number, params: MessageStore }) {
    const { data } = await SupportIdMessagePostRequest(
      payload.ticketID,
      payload.params,
    )

    this.addTicketMessage(data)

    return data
  }

  @Action({ rawError: true })
  async closeTicket (ticketID: number) {
    const { data } = await SupportIdClosePostRequest(ticketID)

    this.setTicket(data)

    return data
  }

  // ---------------------------- FAQ ---------------------------- >>
  faqSearch = ''
  faqList: NameValueResource[] = []
  openedFAQList: Record<number, FaqResource> = {}

  @Mutation
  setFAQSearch(value: string) {
    this.faqSearch = value
  }

  @Mutation
  setFAQList(payload: NameValueResource[]) {
    this.faqList = [...payload]
  }

  @Mutation
  addFAQItem(payload: FaqResource) {
    this.faqList.push({
      name: payload.question,
      value: payload.id,
    })
  }

  @Mutation
  addOpenedFAQList(payload: FaqResource) {
    this.openedFAQList[payload.id] = payload
  }

  @Mutation
  updateFAQItem(faq: FaqResource) {
    const findIndex = this.faqList.findIndex(item => item.value as number === faq.id)
    if (findIndex !== -1) {
      this.faqList[findIndex] = {
        ...this.faqList[findIndex],
        name: faq.question,
      }
    }
  }

  @Mutation
  updateOpenedFAQList(faq: FaqResource) {
    this.openedFAQList[faq.id] = faq
  }

  @Mutation
  deleteFAQItem(id: number) {
    const findIndex = this.faqList.findIndex(item => item.value as number === id)
    if (findIndex !== -1) {
      this.faqList.splice(findIndex, 1)
    }
  }

  @Mutation
  deleteOpenedFAQList(id: number) {
    delete this.openedFAQList[id]
  }

  @Action({ rawError: true })
  async fetchFAQList(params?: FaqGetParams) {
    const { data } = await FaqGetRequest(params)
    this.setFAQList(data)
    return data
  }

  @Action({ rawError: true })
  async fetchFAQ(id: number) {
    const { data } = await FaqIdGetRequest(id)
    this.addOpenedFAQList(data)
    return data
  }

  @Action({ rawError: true })
  async createFAQ(payload: FaqStoreRequest) {
    const { data } = await FaqPostRequest(payload)
    this.addFAQItem(data)
    this.addOpenedFAQList(data)
    return data
  }

  @Action({ rawError: true })
  async updateFAQ(payload: { id: number, body: FaqStoreRequest }) {
    const { data } = await FaqIdPostRequest(payload.id, payload.body)
    this.updateFAQItem(data)
    this.updateOpenedFAQList(data)
    return data
  }

  @Action({ rawError: true })
  async deleteFAQ(id: number) {
    const { data } = await FaqIdDeleteRequest(id)
    this.deleteFAQItem(id)
    this.deleteOpenedFAQList(id)
    return data
  }
}

const SupportModule = getModule(Support)

export default SupportModule
