import { render, staticRenderFns } from "./Tag.vue?vue&type=template&id=41f986ab&scoped=true&lang=pug&"
import script from "./Tag.vue?vue&type=script&lang=ts&"
export * from "./Tag.vue?vue&type=script&lang=ts&"
import style0 from "./Tag.vue?vue&type=style&index=0&id=41f986ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41f986ab",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VIcon})
