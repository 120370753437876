import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import {
  MasterEducationMasterGroupIdExercisesGetRequest,
  ExerciseMasterCardResourceContainer,
  MasterEducationMasterGroupIdExercisesExerciseUuidGetRequest,
  EducationLargeExerciseResource,
  MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidGetRequest,
  EducationLargeTaskResource,
  MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesGetRequest,
  MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesPostRequest,
  EducationLargeTaskResultRequest,
  MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidResultPostRequest,
  MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidDecidePostRequest,
  EducationLargeTaskDecideRequest,
  MessageStore,
  MessageResource,
  MasterEducationMasterGroupIdExercisesGetParams,
  MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidGetParams,
  MistakeShortResource,
  MasterEducationMasterGroupIdMistakesFormIdGetRequest,
  MistakeStoreData,
  MasterEducationMasterGroupIdMistakesFormIdQuestionIdGetRequest,
  MasterEducationMasterGroupIdMistakesFormIdQuestionIdPostRequest,
  TaskDraftStoreData,
  DraftsTaskTaskUuidMasterIdPostRequest, DraftsTaskTaskUuidMasterIdGetRequest,
} from '@/store/types'
import { timeout } from '@/utils/constants'

/**
 * Работа мастера с домашками
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MasterExercises',
  namespaced: true,
  store,
})
class MasterExercises extends VuexModule {
  // ---------------------------- Exercises ---------------------------- >>
  exerciseContainers: ExerciseMasterCardResourceContainer[] = []
  exerciseContainersFilter: MasterEducationMasterGroupIdExercisesGetParams = {}

  @Mutation
  setExerciseContainers (payload: ExerciseMasterCardResourceContainer[]) {
    this.exerciseContainers = [...payload]
  }

  @Mutation
  setExerciseContainersFilter(payload: MasterEducationMasterGroupIdExercisesGetParams) {
    this.exerciseContainersFilter = Object.assign({}, payload)
  }

  @Mutation
  setExerciseContainersFilterMonth(monthId: number) {
    this.exerciseContainersFilter = {
      ...this.exerciseContainersFilter,
      monthId,
    }
  }

  @Mutation
  resetExerciseContainersFilterMonth() {
    this.exerciseContainersFilter = {
      ...this.exerciseContainersFilter,
      monthId: undefined,
    }
  }

  @Action({ rawError: true })
  async fetchExerciseContainers (payload: { masterGroupID: number, params: MasterEducationMasterGroupIdExercisesGetParams }) {
    const { data } = await MasterEducationMasterGroupIdExercisesGetRequest(payload.masterGroupID, payload.params)
    this.setExerciseContainers(data)
    return data
  }

  // ---------------------------- Exercise ---------------------------- >>

  exercise: EducationLargeExerciseResource | null = null

  @Mutation
  setExercise (payload: EducationLargeExerciseResource) {
    this.exercise = Object.assign({}, payload)
  }

  @Mutation
  clearExercise() {
    this.exercise = null
  }

  @Action({ rawError: true })
  async fetchExercise (payload: { masterGroupID: number, exerciseUUID: string }) {
    const { data } = await MasterEducationMasterGroupIdExercisesExerciseUuidGetRequest(payload.masterGroupID, payload.exerciseUUID)
    this.setExercise(data)
    return data
  }

  // ---------------------------- Exercise task ---------------------------- >>
  task: EducationLargeTaskResource | null = null

  @Mutation
  setExerciseTask (payload: EducationLargeTaskResource) {
    this.task = Object.assign({}, payload)
  }

  @Mutation
  clearExerciseTask () {
    this.task = null
  }

  @Mutation
  setContentTask(payload: string) {
    if (this.task)
      this.task = Object.assign(this.task, { content: payload })
  }

  @Mutation
  setHashTask(payload: string) {
    if (this.task)
      this.task = Object.assign(this.task, { draftHash: payload })
  }


  @Action({ rawError: true })
  async fetchExerciseTask (payload: { masterGroupID: number, exerciseUUID: string, taskUUID: string, params?: MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidGetParams }) {
    const { data } = await MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidGetRequest(payload.masterGroupID, payload.exerciseUUID, payload.taskUUID, payload.params)
    if (!payload.params) {
      this.setExerciseTask(data)
    }
    return data
  }

  @Action({ rawError: true })
  async executeExerciseCreativeTask (payload: { masterGroupID: number, exerciseUUID: string, taskUUID: string, params: EducationLargeTaskResultRequest }) {
    const { data } = await MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidResultPostRequest(payload.masterGroupID, payload.exerciseUUID, payload.taskUUID, payload.params)
    this.setExerciseTask(data)
    return data
  }

  @Action({ rawError: true })
  async executeExercisePracticeAndTestTask (payload: { masterGroupID: number, exerciseUUID: string, taskUUID: string, params: EducationLargeTaskDecideRequest }) {
    const { data } = await MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidDecidePostRequest(payload.masterGroupID, payload.exerciseUUID, payload.taskUUID, payload.params)
    this.setExerciseTask(data)
    return data
  }

  @Action({ rawError: true })
  async saveDraftTask(payload: { body: TaskDraftStoreData, masterId: number, taskUuid: string }) {
    const res = await DraftsTaskTaskUuidMasterIdPostRequest(payload.taskUuid, payload.masterId, payload.body, { loading: false, timeout })
    if (this.task && this.task.uuid === payload.taskUuid) {
      this.setContentTask(res.data.content)
      this.setHashTask(res.data.hash)
    }
    return res
  }

  @Action({ rawError: true })
  async fetchDraftTask(payload: { masterId: number, taskUuid: string }) {
    const { data } = await DraftsTaskTaskUuidMasterIdGetRequest(payload.taskUuid, payload.masterId, { loading: false })
    if (this.task && this.task.uuid === payload.taskUuid) {
      this.setHashTask(data.hash)
    }
    return data
  }

  // ----------------------------------- Mistakes ----------------------------------- >>
  // Вопросы, требующие РНО
  mistakes: MistakeShortResource[] = []
  // Проходится ли РНО
  isMistakesStarted = false
  // Текущий вопрос РНО
  currentMistake: MistakeShortResource | null = null

  @Mutation
  setMistakes(payload: MistakeShortResource[]) {
    this.mistakes = payload
  }

  @Mutation
  setMistake(payload: MistakeShortResource) {
    this.mistakes = this.mistakes.map(mistake => mistake.questionId === payload.questionId ? payload : mistake)
  }

  @Mutation
  setStartMistakes() {
    this.isMistakesStarted = true
  }

  @Mutation
  setEndMistakes() {
    this.isMistakesStarted = false
  }

  @Mutation
  setCurrentMistake(id: MistakeShortResource) {
    this.currentMistake = id
  }

  @Mutation
  unsetCurrentMistake() {
    this.currentMistake = null
  }

  @Action({ rawError: true })
  async fetchMistakes(payload: { formID: number, masterGroupID: number }) {
    const { data } = await MasterEducationMasterGroupIdMistakesFormIdGetRequest(payload.masterGroupID, payload.formID)
    this.setMistakes(data)
    if (data.length) {
      this.setCurrentMistake(data.find(question => !question.completed) || data[0])
    }
    return data
  }

  @Action({ rawError: true })
  async fetchMistake(payload: { formID: number, masterGroupID: number, questionID: number }) {
    const { data } = await MasterEducationMasterGroupIdMistakesFormIdQuestionIdGetRequest(payload.masterGroupID, payload.formID, payload.questionID)
    this.setCurrentMistake(data)
    return data
  }

  @Action({ rawError: true })
  async saveMistake(payload: { body: MistakeStoreData, formId: number, masterGroupID: number, questionId: number }) {
    const { data } = await MasterEducationMasterGroupIdMistakesFormIdQuestionIdPostRequest(payload.masterGroupID, payload.formId, payload.questionId, payload.body)
    this.setMistake({
      completed: payload.body.completed,
      correction: payload.body.correction,
      formId: payload.formId,
      questionId: payload.questionId,
    })
    return data
  }

  // ---------------------------- Exercise task messages ---------------------------- >>
  messages: MessageResource[] = []

  @Mutation
  setTaskMessages (payload: MessageResource[]) {
    this.messages = [...payload]
  }

  @Mutation
  clearTaskMessages () {
    this.messages = []
  }

  @Mutation
  addTaskMessage (payload: MessageResource) {
    // this.task.formId === payload.parentId
    if (this.task) {
      this.messages.push(payload)
    }
  }

  @Action({ rawError: true })
  async fetchTaskMessages (payload: { masterGroupID: number, exerciseUUID: string, taskUUID: string }) {
    const { data } = await MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesGetRequest(payload.masterGroupID, payload.exerciseUUID, payload.taskUUID)
    this.setTaskMessages(data)
    return data
  }

  @Action({ rawError: true })
  async sendTaskMessage (payload: { masterGroupID: number, exerciseUUID: string, taskUUID: string, params: MessageStore }) {
    const { data } = await MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesPostRequest(payload.masterGroupID, payload.exerciseUUID, payload.taskUUID, payload.params)
    this.addTaskMessage(data)
    return data
  }
}

const MasterExercisesModule = getModule(MasterExercises)

export default MasterExercisesModule
