












import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  CourseType,
  ExerciseAccessLevel,
  ExerciseStatus,
  GroupType,
  OrderStatus,
  TicketStatus,
  UserStatus,
} from '@/store/types'

@Component({
  inheritAttrs: false,
})
export default class Tag extends Vue {
  @Prop({
    default: 'orange',
    validator (value: string): boolean {
      return !!value.match(/(orange|blue|teal|red|grey|green|purple|white|white-dark|brown|teal-lighten-4|grey-pink|deep-purple|coral|indigo)/)
    },
  })
  private color!: 'orange'|'blue'|'teal'|'red'|'grey'|'green'|'purple'|'white'|'white-dark'|'teal-lighten-4'|'brown'|'grey-pink'|'deep-purple'|'coral'|'indigo'

  @Prop({
    default: 'fill',
    validator (value: string): boolean {
      return !!value.match(/(fill|bright|stroke|stroke-bcg)/)
    },
  })
  private type!: 'fill'|'bright'

  @Prop({ default: null })
  private courseType!: CourseType

  @Prop({ default: null })
  private groupType!: GroupType | 'special'

  @Prop({ default: null })
  private exerciseAccessLevel!: ExerciseAccessLevel

  @Prop({ default: null })
  private ticketStatus!: TicketStatus

  @Prop({ default: null })
  private orderStatus!: OrderStatus

  @Prop({ default: null })
  private userStatuses!: UserStatus

  @Prop({ default: null })
  private exerciseStatuses!: ExerciseStatus

  @Prop({ default: false })
  private large!: boolean

  @Prop({ default: '' })
  private icon!: string

  private get normColor () {
    let color = this.color

    if (this.courseType) {
      switch (this.courseType) {
      case CourseType.DEFAULT:
        color = 'teal'
        break
      case CourseType.SPECIAL:
        color = 'purple'
        break
      }
    }

    if (this.groupType) {
      switch (this.groupType) {
      case GroupType.DEFAULT:
        color = 'teal'
        break
      case GroupType.PLUS:
        color = 'orange'
        break
      case GroupType.LITE:
        color = 'blue'
        break
      case 'special':
        color = 'purple'
        break
      }
    }

    if (this.exerciseAccessLevel) {
      switch (this.exerciseAccessLevel) {
      case ExerciseAccessLevel.PUBLIC:
        color = 'teal'
        break
      case ExerciseAccessLevel.PERSONAL:
        color = 'orange'
        break
      }
    }

    if (this.ticketStatus) {
      switch (this.ticketStatus) {
      case TicketStatus.CLOSED:
        color = 'teal'
        break
      case TicketStatus.IN_WORK:
        color = 'orange'
        break
      case TicketStatus.OPEN:
        color = 'blue'
        break
      }
    }

    if (this.orderStatus) {
      switch (this.orderStatus) {
      case OrderStatus.CANCELED:
        color = 'grey'
        break
      case OrderStatus.PAID:
        color = 'teal'
        break
      case OrderStatus.REFUND:
        color = 'red'
        break
      case OrderStatus.WAIT:
        color = 'orange'
        break
      }
    }

    if (this.userStatuses) {
      switch (this.userStatuses) {
      case UserStatus.ACTIVE:
        color = 'blue'
        break
      case UserStatus.INACTIVE:
        color = 'grey'
        break
      }
    }

    if (this.exerciseStatuses) {
      switch (this.exerciseStatuses) {
      case ExerciseStatus.COMPLETE:
        color = 'teal'
        break
      case ExerciseStatus.ON_CHECK:
        color = 'orange'
        break
      }
    }

    return color
  }
}
