import auth from '@/router/_middleware/auth'
import manageSocials from '@/router/_middleware/manageSocials'

import coursesRoutes from './courses'
import exercisesRoutes from './exercises'
import lessonsRoutes from './lessons'
import storeRoutes from './store'
import supportRoutes from './support'
import progressRoutes from './progress'

export default [
  {
    children: [
      ...coursesRoutes,
      ...exercisesRoutes,
      ...lessonsRoutes,
      ...storeRoutes,
      ...supportRoutes,
      ...progressRoutes,
      {
        component: () => import('@/views/master/MasterDashboard.vue'),
        meta: { middleware: [auth] },
        name: 'master',
        path: '',
      },
      {
        component: () => import('@/views/master/calendar/Calendar.vue'),
        meta: { middleware: [auth, manageSocials] },
        name: 'master.calendar',
        path: '/master/calendar',
      },
      {
        component: () => import('@/views/master/Journal.vue'),
        meta: { middleware: [auth, manageSocials] },
        name: 'master.journal',
        path: '/master/journal',
      },
      {
        component: () => import('@/views/master/Help.vue'),
        meta: { middleware: [auth, manageSocials] },
        name: 'master.help',
        path: '/master/help',
      },
      {
        children: [
          {
            component: () => import('@/views/master/depository/Materials.vue'),
            meta: { middleware: [auth, manageSocials] },
            name: 'master.depository.item.materials',
            path: '/master/depository/:courseID/materials',
          },
          {
            component: () => import('@/views/master/depository/TrainingFiles.vue'),
            meta: { middleware: [auth, manageSocials] },
            name: 'master.depository.item.files',
            path: '/master/depository/:courseID/files',
          },
        ],
        component: () => import('@/views/master/depository/Item.vue'),
        meta: { middleware: [auth, manageSocials] },
        name: 'master.depository.item',
        path: '/master/depository/:courseID',
        redirect: '/master/depository/:courseID/materials',
      },
      {
        children: [
          {
            component: () => import('@/views/master/depository/Folder.vue'),
            meta: { middleware: [auth, manageSocials] },
            name: 'master.depository.item.files.month.folder',
            path: '/master/depository/:courseID/files/:monthID/:folderID',
          },
        ],
        component: () => import('@/views/master/depository/Month.vue'),
        exact: true,
        meta: { middleware: [auth, manageSocials] },
        name: 'master.depository.item.files.month',
        path: '/master/depository/:courseID/files/:monthID',
      },
      {
        component: () => import('@/views/master/depository/List.vue'),
        meta: { middleware: [auth, manageSocials] },
        name: 'master.depository',
        path: '/master/depository',
      },
      {
        component: () => import('@/views/master/MasterRepetitionTest.vue'),
        meta: { middleware: [auth, manageSocials] },
        name: 'master.repetition',
        path: '/master/repetition/:masterGroupID',
      },
    ],
    component: () => import('@/views/Master.vue'),
    meta: { middleware: [auth] },
    path: '/master',
  },
]
