import { ItemSubType, ItemType } from './schema.progress'

export type ItemSubtype<ItemBaseType> =
  ItemBaseType extends ItemType.BACKGROUND_COLOR ? ItemSubType.COLOR | ItemSubType.TEXTURE :
  ItemBaseType extends ItemType.CHARACTER_COLOR ? ItemSubType.COLOR | ItemSubType.TEXTURE :
  ItemBaseType extends ItemType.FRAME_COLOR ? ItemSubType.COLOR :
  ItemBaseType extends ItemType.FRAME_TYPE ? never :
  ItemBaseType extends ItemType.CHARACTER_POSE ? never : unknown

export enum CourseSubjectType {
  HISTORY = 'history',
  SOCIAL = 'social',
  LAW_SCHOOL = 'law-school',
  OLYMPIAD = 'olympiad',
  LITERATURE = 'literature',
  RUSSIAN_LANGUAGE = 'russian-language',
  ENGLISH_LANGUAGE = 'english-language',
  OLYMPIAD_SOCIAL_SCIENCE = 'olympiad-social-science',
  BIOLOGY = 'biology',
  CHEMISTRY = 'chemistry',
  MATHEMATICS = 'mathematics',
  PHYSICS = 'physics',
}
