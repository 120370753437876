




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// store
import { NewsRequestResource } from '@/components/GlobalModals.vue'

@Component({
  components: {
    Dialog,
    ModalWrapper,
  },
})
export default class NewsModal extends Vue {
  @Prop({ required: true })
  private news!: NewsRequestResource

  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: false })
  private blank!: boolean

  private localVisible = this.visible

  private handleClose () {
    this.localVisible = false
    this.$emit('closeModal')
  }

  private handleClickLink() {
    if (this.news && this.news.url) {
      if (!this.blank) {
        window.location.href = this.news.url
      } else {
        window.open(this.news.url, '_blank')
      }
    }
  }

  @Watch('visible')
  watchVisible(value: boolean) {
    this.localVisible = value
  }
}
